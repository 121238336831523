'use client';
import clsx from 'clsx';
import Image from 'next/image';

const logos = [
	{
		key: 'ph',
		size: 'w-[200px]',
		name: 'Product Hunt',
		link: 'https://producthunt.com',
	},
	{ key: 's', size: 'w-[50px]', name: 'Sidebar', link: 'https://sidebar.io' },
	{ key: 'r', size: 'w-[130px]', name: 'Reddit', link: 'https://reddit.com' },
	{
		key: 'td',
		size: 'w-[200px]',
		name: 'Tools Design',
		link: 'https://www.toools.design',
	},
	{ key: 'x', size: 'w-[50px]', name: 'X', link: 'https://x.com' },
	{
		key: 'up',
		size: 'w-[200px]',
		name: 'UX Planet',
		link: 'https://uxplanet.org',
	},
];

const FeaturedOnSection = () => {
	return (
		<div className='container mx-auto py-6 px-4 md:flex justify-center items-center gap-24'>
			<h2 className='text-xl md:text-lg md:font-semibold mb-8 md:mb-0 text-gray-700 md:text-gray-400 text-center md:text-left'>
				Featured On
			</h2>
			<div className='flex items-center justify-center gap-8 md:gap-3 flex-wrap'>
				{logos.map((logo) => (
					<a key={logo.key} href={logo.link} target='_blank' rel='noreferrer'>
						<div
							key={logo.key}
							className={clsx('h-[30px] max-w-[160px] relative ', logo.size)}
						>
							<Image
								src={`/featured-on/${logo.key}.svg`}
								alt={`${logo.name} logo`}
								fill
								sizes='(max-width: 768px) 50vw, 100vw'
								priority={false}
								className='transition duration-300 ease-in-out object-contain hover:scale-105 opacity-20'
							/>
						</div>
					</a>
				))}
			</div>
		</div>
	);
};

export default FeaturedOnSection;
