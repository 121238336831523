'use client';

import { CalendarIcon } from '@/components/Icons';
import { Button } from '@/components/ui/button';
import { cookieHasConverted } from '@/constants';
import { Analytics } from '@/lib/mixpanel';
import { Routes } from '@/lib/routes';
import { useCookies } from 'next-client-cookies';
import Image from 'next/image';
import Link from 'next/link';

const ProminentFeaturesSection = () => {
	const cookies = useCookies();
	const handleConvertLead = () => {
		if (cookies.get(cookieHasConverted)) return;
		Analytics.track('lead_converted');
		cookies.set(cookieHasConverted, 'true');
	};

	return (
		<div
			className='flex max-[900px]:flex-col-reverse justify-center items-center min-[900px]:items-start content-start gap-[38px] max-[900px]:gap-[64px]'
			id='prominent-section'
		>
			<div className='flex flex-col items-center gap-[42px] px-4 max-w-[420px]'>
				<div className='flex flex-col relative gap-8 px-4 w-full max-w-[420px]'>
					<h4 className='relative text-xl text-primary font-semibold max-w-2xl leading-tight text-slate-500'>
						In every challenge email you will receive a link to check-in.
						<br />
						<br />
						Checking-in will extends your streak. It's a fun way to develop a
						habit while keep you motivated and engaged with your challenge.
					</h4>
					<div>
						<Link
							aria-label='Link to Schedule Challenge'
							href={Routes.scheduler}
							onClick={handleConvertLead}
						>
							<div className='relative'>
								<Button size='md' variant='outline'>
									<CalendarIcon className='w-[20px] h-[20px]' />
									Schedule Challenge
								</Button>
							</div>
						</Link>
					</div>
				</div>
			</div>
			<div className='flex flex-col items-center gap-[42px] px-4 w-full max-w-[460px]'>
				<Image
					src='/landing/feature-highlight-streaks.webp'
					width={800}
					height={400}
					alt='Streaks feature highlight'
					priority={false}
					className='min-w-[100px]'
				/>
			</div>
		</div>
	);
};

export default ProminentFeaturesSection;
