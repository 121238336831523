'use client';

import { lsAppPrefix, lsKeys } from '@/constants';
import { Analytics } from '@/lib/mixpanel';
import { clsx } from 'clsx';
import { HeartIcon } from 'lucide-react';
import { useLocalStorage } from 'usehooks-ts';
import { Button } from '../../ui/button';

const features = [
	{
		key: '365-challenges',
		icon: '🗓',
		label: '365 Unique Challenges',
		emphasis: 'A year of challenges:',
		content: `Crafted to spark your
	creativity and expand your skills. With more than 365 diverse and
	engaging tasks, your design journey will always be fresh and
	exciting.`,
		isComingSoon: false,
	},
	{
		key: 'history',
		icon: '📖',
		label: 'History of Challenges',
		emphasis: 'Track Your Progress:',
		content: `See how far you've come with a
	history of completed challenges. Reflect on your growth, revisit
	past designs, and celebrate your journey every step of the way.`,
		isComingSoon: false,
	},
	{
		key: 'notifications',
		icon: '🔔',
		label: 'Daily Notifications',
		emphasis: 'Stay On Track:',
		content: `We'll keep you motivated with daily
	reminders. Never miss a chance to learn and grow. The notifications
	are designed to make sure you're always engaged with your next
	design challenge.`,
		isComingSoon: false,
	},
	{
		key: 'ui-gallery',
		icon: '🖼️',
		label: 'Gallery',
		emphasis: 'Get Inspired:',
		content: `Explore the work of fellow designers
	and get inspired. The gallery is a place to showcase your work,
	admire the creativity of others, and share your feedback.`,
		isComingSoon: false,
	},
	{
		key: 'ai-validation',
		icon: '👨‍🏫',
		label: 'Get Direct Design feedback',
		emphasis: 'Verify & Improve:',
		content: `Get feedback on your submitted
	designs to learn and understand better. This feature reviews your
	work, ensuring it follows guidelines and offering insights for
	improvement.`,
		isComingSoon: true,
	},
	// {
	// 	key: 'sharing',
	// 	icon: '💬',
	// 	label: 'Social Sharing',
	// 	emphasis: 'Share and Compete:',
	// 	content: `Make sharing your designs an
	// adventure. Showcase your work with flair, engage with a community of
	// fellow designers, and even partake in friendly competition.`,
	// 	isComingSoon: true,
	// },
	// {
	// 	key: 'leaderboard',
	// 	icon: '🏆',
	// 	label: 'Leaderboard',
	// 	emphasis: 'Join the Ranks:',
	// 	content: `As you complete challenges, climb the
	// leaderboard and gain recognition among your peers. It's not just about
	// competition; it's about inspiring each other to reach new heights in
	// design.`,
	// 	isComingSoon: true,
	// },
];

const FeaturesSection = () => {
	const [showedInterestItems, setShowedInterestItems] = useLocalStorage<
		string[] | null | undefined
	>(`${lsAppPrefix}/${lsKeys.interestFeatures}`, []);
	const handleShowInterest = (featureKey: string) => {
		Analytics.track('feature_show_interest', {
			feature: featureKey,
		});
		setShowedInterestItems([...(showedInterestItems || []), featureKey]);
	};

	return (
		<div
			className='flex flex-wrap justify-center items-start content-start gap-[32px]'
			id='features-section'
		>
			{features.map((feature) =>
				feature.isComingSoon ? (
					<div
						key={feature.key}
						className='relative flex flex-col items-center gap-[14px] mt-[20px] py-[25px] px-[35px] max-w-[320px] bg-white shadow-md rounded-2xl'
					>
						<div className='absolute -top-[18px] bg-50%_50% bg-[linear-gradient(91deg,_#ebf2fd_0%,#fcd7f4_108%)] bg-blend-normal bg-cover bg-no-repeat flex flex-row h-8 items-center justify-center px-6 rounded-[34px]'>
							<div className="text-sm font-['Figtree'] font-semibold leading-[15.4px] text-[#414141] flex gap-2 items-center">
								<Button
									size={'sm'}
									variant={'ghost'}
									className={clsx(
										'inline p-0 h-[22px] rounded-full text-red-400 fill-red-400 hover:bg-transparent hover:text-red-800',
										showedInterestItems?.includes(feature.key)
											? 'hover:text-red-400'
											: 'hover:text-red-800',
									)}
									onClick={() => {
										!showedInterestItems?.includes(feature.key) &&
											handleShowInterest(feature.key);
									}}
								>
									<HeartIcon
										fill={
											showedInterestItems?.includes(feature.key)
												? 'inherit'
												: 'transparent'
										}
										className='w-[20px] h-[20px]'
									/>
								</Button>
								Coming Soon
							</div>
						</div>
						<h4 className='text-xl font-bold text-center'>{feature.label}</h4>
						<p className='text-center'>
							<strong>{feature.emphasis}</strong> {feature.content}
						</p>
					</div>
				) : (
					<div
						key={feature.key}
						className='relative max-w-[320px] bg-white shadow-md rounded-2xl p-1 bg-gradient-to-br from-blue-200 to-fuchsia-300'
					>
						<div className='flex flex-col items-center gap-[14px] py-[25px] px-[35px] bg-white rounded-xl'>
							<span className='text-4xl'>{feature.icon}</span>
							<h4 className='text-xl font-bold text-center'>{feature.label}</h4>
							<p className='text-center'>
								<strong>{feature.emphasis}</strong>
								{feature.content}
							</p>
						</div>
					</div>
				),
			)}
		</div>
	);
};

export default FeaturesSection;
