'use client';

import { Button } from '@/components/ui/button';
import { Analytics } from '@/lib/mixpanel';
import { Routes } from '@/lib/routes';
import { Wand2Icon } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const images = [
	{
		id: 95,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/b966dc5c-33c7-4911-bfe2-c8704ea6525f-u-923e06d5-ea34-4a84-996e-855fc4c9ab45top_bar_&_navigation_bar-pp-1714533704498.png',
		username: 'dr_dred',
	},
	{
		id: 56,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/2210514a-7927-475b-b155-0e955f7c64bd-u-9728f555-6aff-4973-a4a6-29e306383616writing-pp-1711402206890.png',
		username: 'popelz',
	},
	{
		id: 74,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/5455ef34-6dcd-4b98-b5eb-b99932811b9d-u-2420df1a-c6ce-4bb6-8b56-8087ccf41dccsuggestions_&_similar_items-pp-1711905562224.png',
		username: 'subatomicautobiography',
	},
	{
		id: 14,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/c2b0fc49-4055-481c-80f4-d0f44f95e458-u-681f70a8-1d2d-4d58-88d4-340c43e0ba56checkbox-pp-1709822987298.png',
		username: 'mare_di_mare',
	},
	{
		id: 70,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/3471f41e-b56f-4ce3-8300-5a569290e162-u-a249e066-3d28-451e-bb43-cd6913990e30playing_audio-pp-1711762055221.png',
		username: 'myebstudios',
	},
	{
		id: 94,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/b966dc5c-33c7-4911-bfe2-c8704ea6525f-u-923e06d5-ea34-4a84-996e-855fc4c9ab45link-pp-1714533453152.png',
		username: 'dr_dred',
	},
	{
		id: 32,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/c2b0fc49-4055-481c-80f4-d0f44f95e458-u-681f70a8-1d2d-4d58-88d4-340c43e0ba56enterprise_page-pp-1710437133918.png',
		username: 'mare_di_mare',
	},
	{
		id: 83,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/267725c0-b6fe-4c01-8acd-ec527d1dba02-u-b5c8c73b-9952-458f-ad1e-52da5d4f6c2fsong_&_podcast_detail-pp-1713373632742.png',
		username: 'Suffian',
	},
	{
		id: 60,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/2210514a-7927-475b-b155-0e955f7c64bd-u-9728f555-6aff-4973-a4a6-29e306383616spinner-pp-1711486945553.png',
		username: 'popelz',
	},
	{
		id: 48,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/c2b0fc49-4055-481c-80f4-d0f44f95e458-u-681f70a8-1d2d-4d58-88d4-340c43e0ba56canceling_order_&_refunding-pp-1711054373975.png',
		username: 'mare_di_mare',
	},
	{
		id: 78,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/b83ab2f4-b9a5-4328-a4ef-1c9b7090b50d-u-11c80d66-1143-42cd-84df-4b4184f3d338404_page-pp-1712689913612.png',
		username: 'design_elf',
	},
	{
		id: 58,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/c2b0fc49-4055-481c-80f4-d0f44f95e458-u-681f70a8-1d2d-4d58-88d4-340c43e0ba56ban_&_block-pp-1711442434470.png',
		username: 'mare_di_mare',
	},
	{
		id: 61,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/3471f41e-b56f-4ce3-8300-5a569290e162-u-a249e066-3d28-451e-bb43-cd6913990e30fridge_control_interface-pp-1711493982540.png',
		username: 'myebstudios',
	},

	{
		id: 11,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/c2b0fc49-4055-481c-80f4-d0f44f95e458-u-681f70a8-1d2d-4d58-88d4-340c43e0ba56home-pp-1709701319297.png',
		username: 'mare_di_mare',
	},
	{
		id: 79,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/5c6d0ba8-3d8a-4dfd-b0fd-abeff22fbf98-u-3462dd7f-c7f7-456c-986b-dc20602d2b84commenting_&_replying-pp-1712755868121.png',
		username: 'hipghost',
	},
	{
		id: 63,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/c2b0fc49-4055-481c-80f4-d0f44f95e458-u-681f70a8-1d2d-4d58-88d4-340c43e0ba56branding-pp-1711529818465.png',
		username: 'mare_di_mare',
	},
	{
		id: 89,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/9c3589e5-57d0-48ed-99b8-37bd8397949d-u-8405bad6-aeb7-4998-91df-f1c36a3ac05einline_edit-pp-1714117326339.png',
		username: 'sobersmile',
	},
	{
		id: 75,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/3471f41e-b56f-4ce3-8300-5a569290e162-u-a249e066-3d28-451e-bb43-cd6913990e30live-viewing_apps-pp-1712026582525.png',
		username: 'myebstudios',
	},
	{
		id: 24,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/c2b0fc49-4055-481c-80f4-d0f44f95e458-u-681f70a8-1d2d-4d58-88d4-340c43e0ba56confirmation-pp-1710102076648.png',
		username: 'mare_di_mare',
	},
	{
		id: 57,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/3471f41e-b56f-4ce3-8300-5a569290e162-u-a249e066-3d28-451e-bb43-cd6913990e30onboarding_(spotlight)-pp-1711407390139.png',
		username: 'myebstudios',
	},
	{
		id: 90,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/8a144eef-358a-49ec-9b37-381571436687-u-f0844569-3b8a-4103-b92c-3ce74f0bd3basettings_&_preferences-pp-1714239495924.jpg',
		username: 'JohnnyUI_Wi',
	},
	{
		id: 85,
		url: 'https://cdn.uichallenges.design/public-checkins/v1/c2b0fc49-4055-481c-80f4-d0f44f95e458-u-681f70a8-1d2d-4d58-88d4-340c43e0ba56charts-pp-1713511452418.png',
		username: 'mare_di_mare',
	},
];

const GalleryPeekSection = () => {
	const handleConvertLead = async () => {
		Analytics.track('lead_converted');
	};

	const [offset, setOffset] = useState(0);
	const timeoutRef = useRef<NodeJS.Timeout>();

	useEffect(() => {
		startSliding();
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	const startSliding = () => {
		timeoutRef.current = setInterval(() => {
			setOffset((prevOffset) => (prevOffset - 1) % images.length);
		}, 2000);
	};

	const getSlideStyle = (index: number) => ({
		width: '600px',
		height: '400px',
		backgroundSize: 'cover',
		backgroundImage: `url(${
			images[(index + offset + images.length) % images.length]
		})`,
		transition: 'transform 1s ease-in-out',
		transform: `translateX(${offset * 600}px)`,
	});

	return (
		<div id='prominent-section'>
			<Carousel
				additionalTransfrom={0}
				arrows={false}
				autoPlay
				autoPlaySpeed={3000}
				transitionDuration={1500}
				centerMode
				draggable
				focusOnSelect={false}
				infinite
				keyBoardControl
				minimumTouchDrag={80}
				renderArrowsWhenDisabled={false}
				renderButtonGroupOutside={false}
				renderDotsOutside={false}
				responsive={{
					desktop: {
						breakpoint: {
							max: 3000,
							min: 1024,
						},
						items: 4,
						partialVisibilityGutter: 100,
					},
					mobile: {
						breakpoint: {
							max: 600,
							min: 0,
						},
						items: 1,
						partialVisibilityGutter: 10,
					},
					largeMobile: {
						breakpoint: {
							max: 768,
							min: 600,
						},
						items: 2,
						partialVisibilityGutter: 10,
					},
					tablet: {
						breakpoint: {
							max: 1024,
							min: 768,
						},
						items: 3,
						partialVisibilityGutter: 100,
					},
				}}
				rewind={false}
				rewindWithAnimation={false}
				rtl={false}
				shouldResetAutoplay
				showDots={false}
				slidesToSlide={1}
				swipeable
			>
				{images.map((image) => (
					<div
						key={image.id}
						className='w-full h-full px-4 pointer-events-none'
					>
						<div key={`${image.id}`} className='aspect-[3/2]'>
							<div className='bg-white rounded-lg shadow-lg overflow-hidden transition-all cursor-pointer relative'>
								<Image
									alt='Gallery Item'
									className='w-full h-full object-cover'
									height={400}
									src={image.url as string}
									style={{
										aspectRatio: '600/400',
										objectFit: 'cover',
										objectPosition: 'center top',
									}}
									width={600}
								/>
							</div>
							<div className='pt-1 flex items-center justify-between'>
								<h3 className='text-md md:text-sm font-semibold'>
									{image.username}
								</h3>
							</div>
						</div>
					</div>
				))}
			</Carousel>
			<div className='flex justify-center pt-6'>
				<Link
					aria-label='Link to Schedule Challenge'
					href={Routes.gallery}
					onClick={handleConvertLead}
				>
					<div className='relative'>
						<Button size='md' variant='outline'>
							<Wand2Icon className='w-[20px] h-[20px]' />
							Get Inspired
						</Button>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default GalleryPeekSection;
