'use client';

import { lsAppPrefix, lsKeys } from '@/constants';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const useReferralCode = () => {
	const params = useSearchParams();
	const [referralCodeSet, setReferralCodeSet] = useState(false);
	const [referralCode, setReferralCode] = useLocalStorage<
		string | null | undefined
	>(`${lsAppPrefix}/${lsKeys.referralCode}`, null);

	useEffect(() => {
		const rCode =
			params.get('r') || params.get('ref') || params.get('utm_source');
		if (!referralCode && rCode) {
			setReferralCode(rCode as string);
			window.history.pushState({}, '', window.location.pathname);
		}
		setReferralCodeSet(true);
	}, [referralCode, params, setReferralCode]);

	return [referralCode, referralCodeSet];
};

export default useReferralCode;
