'use client';

import { cookieHasConverted } from '@/constants';
import { Analytics } from '@/lib/mixpanel';
import { GuidesRoutes, Routes } from '@/lib/routes';
import { LinkIcon } from 'lucide-react';
import { useCookies } from 'next-client-cookies';
import Link from 'next/link';
import { CalendarIcon, RefreshIcon } from '../../Icons';
import { Button } from '../../ui/button';

const OnboardingSection = () => {
	const cookies = useCookies();
	const handleConvertLead = () => {
		if (cookies.get(cookieHasConverted)) return;
		Analytics.track('lead_converted');
		cookies.set(cookieHasConverted, 'true');
	};

	return (
		<div
			className='flex max-[900px]:flex-wrap justify-center items-start content-start gap-[28px] max-[900px]:gap-[64px]'
			id='onboarding-section'
		>
			<div className='flex flex-col items-center gap-[42px] px-4 max-w-[360px]'>
				<div className='flex flex-col relative gap-8 px-4 min-w-[312px]'>
					<div className='w-[3px] absolute left-[29px] top-[12px] h-[90%] -z-10 bg-[#EC73D9]' />
					<div className='flex items-center text-lg gap-4'>
						<div className='rounded-full h-7 w-7 min-h-7 min-w-7 text-[18px] font-black flex items-center justify-center bg-[#EC73D9] text-white'>
							1
						</div>
						<span className='text-gray-700 font-semibold leading-none text-[20px]'>
							Select your challenge
						</span>
					</div>
					<div className='flex items-center text-lg gap-4'>
						<div className='rounded-full h-7 w-7 min-h-7 min-w-7 text-[18px] font-black flex items-center justify-center bg-[#EC73D9] text-white'>
							2
						</div>
						<span className='text-gray-700 font-semibold leading-none text-[20px]'>
							Add your email
						</span>
					</div>
					<div className='flex items-center text-lg gap-4'>
						<div className='rounded-full h-7 w-7 min-h-7 min-w-7 text-[18px] font-black flex items-center justify-center bg-[#EC73D9] text-white'>
							3
						</div>
						<span className='text-gray-700 font-semibold leading-none text-[20px]'>
							Get a daily UI Prompt in your email
						</span>
					</div>
					<div className='flex items-center text-lg gap-4'>
						<div className='rounded-full h-7 w-7 min-h-7 min-w-7 text-[18px] font-black flex items-center justify-center bg-[#EC73D9] text-white'>
							4
						</div>
						<div className='flex flex-col gap-2'>
							<span className='text-gray-700 font-semibold leading-none text-[20px]'>
								Read our design guide and complete the challenge{' '}
							</span>
							<Link
								href={GuidesRoutes.designGuide}
								className='text-[#d687b7] font-bold leading-none text-[18px] flex gap-2 items-center'
							>
								<LinkIcon size={22} /> Read design guide
							</Link>
						</div>
					</div>
					<div className='flex items-center text-lg gap-4'>
						<div className='rounded-full h-7 w-7 min-h-7 min-w-7 text-[18px] font-black flex items-center justify-center bg-[#EC73D9] text-white'>
							5
						</div>
						<span className='text-gray-700 font-semibold leading-none text-[20px]'>
							Share your work with the community{' '}
						</span>
					</div>
				</div>
				<div>
					<Link
						aria-label='Link to Schedule Challenge'
						href={Routes.scheduler}
						onClick={handleConvertLead}
					>
						<div className='relative'>
							<Button size='md'>
								<CalendarIcon className='w-[22px] h-[22px]' strokeWidth='1.5' />
								Schedule Challenge
							</Button>
						</div>
					</Link>
					<p className='text-sm mt-2 text-slate-500'>
						You can always opt-out of the scheduled challenge
					</p>
				</div>
			</div>

			<div className='flex flex-col items-center gap-[42px] px-4 max-w-[360px]'>
				{/* <div className='flex flex-col gap-[16px]'>
					<div className='relative overflow-hidden rounded-xl max-h-[45px]'>
						<Image
							src='/label-bg-schedule.png'
							alt='Schedule header graphics'
							priority
							width={360}
							height={51}
						/>
						<h4 className='text-[26px] font-bold absolute top-1 tracking-wide left-5 text-white'>
							Prompt Generator
						</h4>
					</div>
					<p className='text-[16px] pl-4 pr-2 leading-tight text-slate-500'>
						Generate your challenge manually. Choose when to take on your
						challenge and track your progress in history.
					</p>
				</div> */}

				<div className='flex flex-col relative gap-8 px-4 min-w-[312px]'>
					{/* <div className='w-[3px] absolute left-[29px] top-[12px] h-[90%] -z-10 bg-[#EC73D9]' />
					<div className='flex items-center text-lg gap-4'>
						<div className='rounded-full h-7 w-7 min-h-7 min-w-7 text-[18px] font-black flex items-center justify-center bg-[#EC73D9] text-white'>
							1
						</div>
						<span className='text-gray-700 font-semibold leading-none text-[20px]'>
							Click to generate a unique UI Prompt
						</span>
					</div>
					<div className='flex items-center text-lg gap-4'>
						<div className='rounded-full h-7 w-7 min-h-7 min-w-7 text-[18px] font-black flex items-center justify-center bg-[#EC73D9] text-white'>
							2
						</div>
						<span className='text-gray-700 font-semibold leading-none text-[20px]'>
							Complete it and Share
						</span>
					</div>
					<div className='flex items-center text-lg gap-4'>
						<div className='rounded-full h-7 w-7 min-h-7 min-w-7 text-[18px] font-black flex items-center justify-center bg-[#EC73D9] text-white'>
							3
						</div>
						<span className='text-gray-700 font-semibold leading-none text-[20px]'>
							Track your progress
						</span>
					</div> */}
					<h4 className='relative text-xl text-primary font-semibold max-w-2xl leading-tight text-slate-500'>
						Alternatively, pick a difficulty and Manual Challenge. Just hit a
						button and see what happens!
					</h4>
					<div>
						<Link
							aria-label='Link to Schedule Challenge'
							href={Routes.generator}
							onClick={handleConvertLead}
						>
							<div className='relative'>
								<Button size='md' variant='outline'>
									<RefreshIcon className='w-[20px] h-[20px]' />
									Manual Challenge
								</Button>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default OnboardingSection;
