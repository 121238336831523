export async function submitEmail(email: string) {
	const record = {
		Email: email || '',
		Submitted: new Date().toDateString(),
	};

	const resp = await fetch(
		'https://api.airtable.com/v0/appffKbeXnRqqVF8e/tblWxlRIGLCtrABRg',
		{
			method: 'POST',
			headers: {
				Authorization: `Bearer ${'patppFpItA9vUjPOk.4f0b9f0cac5014728f494a722a78a39856e0132d28342fc5d1861a4b6bbcfb06'}`,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				fields: record,
			}),
		},
	);

	// Apparently sometimes it returns a string so need to check content type
	let atResponseBody = null;
	const contentType = resp.headers.get('content-type');
	if (contentType && contentType.indexOf('application/json') !== -1) {
		atResponseBody = await resp.json();
	} else {
		atResponseBody = await resp.text();
	}

	if (atResponseBody.error || !resp.ok) {
		throw new Error(atResponseBody.error.message);
	}

	return atResponseBody;
}
