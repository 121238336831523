'use client';

import { submitEmail } from '@/app/actions/misc/submitEmail';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { FormEvent, useState } from 'react';
import { Button } from '../ui/button';

const PHWidget = dynamic(() => import('./PHWidget'), { ssr: false });

const FooterCTASection = () => {
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const submitEmailForm = async (event: FormEvent<HTMLFormElement>) => {
		setIsLoading(true);
		setError(null);

		try {
			event.preventDefault();
			const formData = new FormData(event.currentTarget);
			const email = formData.get('email') as string;

			if (!email) {
				throw new Error('Please provide an email address');
			}

			await submitEmail(email);
			setIsSuccess(true);
		} catch (error) {
			// Capture the error message to display to the user
			setError((error as Error).message);
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className='relative' id='footer-cta-section'>
			<Image
				src='/bg-bottom-cta.png'
				alt='UI challenge community'
				fill
				sizes='100vw'
				priority={false}
				className='-z-10 object-cover'
			/>
			<section className='container mx-auto z-10 flex-1 py-48 static'>
				<div className='flex flex-col justify-center items-center max-w-[500px] mx-auto gap-8'>
					<h2 className='text-center text-3xl font-medium leading-none'>
						Stay tuned for the feature releases!
					</h2>
					<div className='flex flex-col gap-4'>
						{isSuccess ? (
							<p className='text-primary text-center text-2xl font-bold'>
								<span className='text-4xl pr-2'>🎉</span> Thank you for signing
								up!
							</p>
						) : (
							<>
								<form
									onSubmit={submitEmailForm}
									className='flex max-[900px]:flex-col gap-4'
								>
									<input
										type='email'
										name='email'
										autoComplete='email'
										placeholder='Your email'
										required
										className='rounded-2xl px-[20px] border-2 border-slate-200 min-w-[300px] max-[900px]:h-[60px]'
									/>
									<Button
										type='submit'
										size='auto'
										disabled={isLoading}
										className={
											'py-[13px] px-[20px] text-xl font-bold rounded-[12px] gap-[12px] flex overflow-hidden relative disabled:opacity-100 disabled:bg-slate-600'
										}
									>
										Get Notified
									</Button>
								</form>
								{error ? (
									<p className='text-red-500 text-center text-sm'>{error}</p>
								) : null}
								<p className='text-sm'>
									By signing up for the release notification you are opting in
									for email updates. Your email address will only be used to
									communicate the updates, and will never be shared with any
									third-party.
								</p>
							</>
						)}
					</div>
					<PHWidget />
				</div>
			</section>
		</div>
	);
};

export default FooterCTASection;
