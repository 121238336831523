'use client';

import { cookieHasConverted } from '@/constants';
import useUserCreate from '@/hooks/use-browser-user-create';
import useReferralCode from '@/hooks/use-referral-code';
import { Analytics } from '@/lib/mixpanel';
import { Routes } from '@/lib/routes';
import { motion } from 'framer-motion';
import { useCookies } from 'next-client-cookies';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useEffect } from 'react';
import { FlyingStarIcon, LogotypeIcon } from '../Icons';
import LearnMoreButton from '../LearnMoreButton';
import { Button } from '../ui/button';

const HeaderAnimation = dynamic(() => import('../HeaderAnimation'), {
	ssr: true,
});

const LandingApp = () => {
	const cookies = useCookies();
	const { handleUserCreate } = useUserCreate();
	// Scrape referral code from the query params and set it to local storage
	const [referralCode, referralCodeSet] = useReferralCode();

	useEffect(() => {
		Analytics.init();
		Analytics.track('page_view', {
			'Page Type': 'Landing Page',
		});

		// Ensures user is set in cookies and indexdb
		handleUserCreate();
	}, []);

	useEffect(() => {
		if (referralCode) {
			Analytics.track('lead_landing', {
				referralCode: referralCode ?? undefined, // Hide from mixpanel if null
			});
		}
	}, [referralCode, referralCodeSet]);

	const handleConvertLead = () => {
		if (cookies.get(cookieHasConverted)) return;
		Analytics.track('lead_converted');
		cookies.set(cookieHasConverted, 'true');
	};

	return (
		<div className='relative min-h-[80vh] min-[900px]:min-h-[70vh] w-full flex flex-col justify-center items-center gap-7 max-[900px]:px-4 min-[900px]:py-12'>
			<HeaderAnimation className='rounded-3xl overflow-hidden' />
			<div className='w-[138px] min-[900px]:w-[190px] relative z-10'>
				<LogotypeIcon />
			</div>
			<h1 className='relative z-10 text-center font-black uppercase text-[64px] tracking-tight leading-none max-w-4xl max-[900px]:text-[40px]'>
				Master UI/UX Design with Daily UI Challenges
			</h1>
			<h2 className='relative z-10 text-center text-2xl not-italic text-primary font-semibold max-w-xl leading-tight'>
				Advance your UI/UX skills with daily challenges, achievement streaks,
				and personalized feedback coming soon.
			</h2>
			<div className='flex gap-4 max-[900px]:flex-col relative z-10'>
				<Link
					aria-label='Link to Schedule Challenge'
					href={Routes.scheduler}
					onClick={handleConvertLead}
				>
					<div className='relative'>
						<Button
							size='auto'
							className='py-[13px] px-[20px] text-xl font-bold rounded-[12px] gap-[12px] flex disabled:opacity-100 disabled:bg-slate-600'
						>
							<FlyingStarIcon className='w-[22px] h-[22px]' strokeWidth='1.5' />
							Get Started
						</Button>
					</div>
				</Link>
			</div>
			<div>
				<motion.div
					className='flex justify-center pt-[6px] w-[140px] mx-auto'
					whileHover='hovered'
				>
					<LearnMoreButton className='bg-transparent' />
				</motion.div>
			</div>
		</div>
	);
};

export default LandingApp;
