'use client';

import { motion } from 'framer-motion';
import { ChevronRight } from 'lucide-react';
import { FeedbackIcon, ProductStarIcon } from './Icons';

const FooterFeedbackWidget = () => {
	return (
		<>
			{/* Product roadmap */}
			<motion.div
				initial='rest'
				whileHover='hover'
				animate='rest'
				transition={{ duration: 0.2 }}
				variants={{
					hover: { width: 263 },
				}}
				className='fixed bottom-[86px] right-[16px] z-50 p-[2px] shadow-xl rounded-full bg-[#F2F2F2] overflow-hidden w-[50px] h-[50px]'
			>
				<motion.a
					href='https://ui-challenges.canny.io/feature-requests'
					target='_blank'
					rel='noopener noreferrer'
					aria-label='Link to a Product roadmap board'
					transition={{ duration: 0.2 }}
					variants={{
						hover: { width: 259 },
					}}
					className='px-[4px] py-[4px] whitespace-nowrap font-medium rounded-full bg-white text-[18px] inline-flex gap-[12px] overflow-hidden w-full items-center leading-none'
				>
					<div className='p-2 rounded-full bg-[#C9C3FF]'>
						<ProductStarIcon
							width='22px'
							className='min-w-[22px] text-[#6959FF]'
							strokeWidth={1.5}
						/>
					</div>
					Shape Our Roadmap
					<ChevronRight className='w-[20px] h-[20px] text-primary' />
				</motion.a>
			</motion.div>

			{/* Feedback */}
			<motion.div
				initial='rest'
				whileHover='hover'
				animate='rest'
				transition={{ duration: 0.2 }}
				variants={{
					hover: { width: 218 },
				}}
				className='fixed bottom-[24px] right-[16px] z-50 p-[2px] shadow-xl rounded-full bg-[#F2F2F2] overflow-hidden w-[50px] h-[50px]'
			>
				<motion.a
					href='https://airtable.com/appmIddQx198t4f5a/shrogHQPML7S3QNWb'
					target='_blank'
					rel='noopener noreferrer'
					aria-label='Link to a Give Feedback form'
					transition={{ duration: 0.2 }}
					variants={{
						hover: { width: 214 },
					}}
					className='px-[4px] py-[4px] whitespace-nowrap font-medium rounded-full bg-white text-[18px] inline-flex gap-[12px] overflow-hidden w-full items-center leading-none'
				>
					<div className='p-2 rounded-full bg-[#FAC3FF]'>
						<FeedbackIcon
							width='22px'
							className='min-w-[22px] text-[#FF59EE]'
							strokeWidth={1.5}
						/>
					</div>
					Give Feedback
					<ChevronRight className='w-[20px] h-[20px] text-primary' />
				</motion.a>
			</motion.div>
		</>
	);
};

export default FooterFeedbackWidget;
