'use client';

import clsx from 'clsx';
import { motion } from 'framer-motion';
import { DownChevronIcon } from './Icons';
import { Button } from './ui/button';

type PropsType = {
	className?: string;
};

const LearnMoreButton = ({ className }: PropsType) => {
	const handleClick = () => {
		// Scroll to features section
		document.getElementById('onboarding-section')?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
	};

	return (
		<Button
			size='auto'
			className={clsx(
				'font-semibold py-[8px] px-[15px] text-base rounded-[12px] gap-[10px] flex overflow-hidden relative text-primary bg-white hover:bg-purple-100',
				className,
			)}
			onClick={handleClick}
		>
			<motion.div
				variants={{
					hovered: {
						y: [0, -3, 0, 3, 0],
						transition: { duration: 0.5, ease: 'easeInOut' },
					},
				}}
			>
				<DownChevronIcon className='w-[12px]' />
			</motion.div>
			Learn More
		</Button>
	);
};

export default LearnMoreButton;
